<template>
  <div class="container mt-4">
    <v-progress-circular
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div v-if="!loading && document">
      <div class="container message-container row">
        <router-link :to="`/documents/${document.id}/general`">
          <button class="back-icon mr-4"></button>
        </router-link>
        <h2 class="pt-2 semibold-font">{{ $t('attachments') }}</h2>
        <span class="grey-square message-square ml-lg-4 ml-2">{{ attachments.length }} {{ $t('attachments_number') }}</span>
      </div>
      <!-- Mobile -->
      <div class="row d-flex d-lg-none mt-0 pt-0 pb-0">
        <v-col cols="9">
          <h4 class="year_h4">
            <span>{{document.attributes['year'] }}</span>
            <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
          </h4>
        </v-col>
        <v-col cols="3" class="text-right">
          <div class="symbol_div">{{document.attributes['symbol'] }}</div>
        </v-col>
      </div>
      <v-simple-table v-if="!loading" class="mt-2 mb-8 documents-table d-block d-lg-none">
        <tbody>
          <tr class="pointer-mouse" @click="openAttachment(attachment)" v-for="(attachment) in attachments" :key="attachment.id">
            <td width="5%" class="pb-4 pt-4">
              <div :class="attachment.counter % 3 === 0  ? 'yellow-color-square' : attachment.counter % 2 === 0  ? 'orange-color-square' : 'purple-color-square'" class="extension-square row">
                <span>{{ attachment.attributes.extension }}</span>
              </div>
            </td>
            <td width="80%" class="pb-4 semibold-font">
              <br>
              <h3>{{ attachment.attributes.filename }}</h3>
              {{ attachment.attributes.description }}
            </td>
            <td width="10%" class="pb-4 text-right">
              {{ $date(attachment.attributes['created_at']).format('DD.MM.YYYY') }}
              <br>
              <br>
              <span class="see-more text-right">
                <router-link class="see-more" :to="'/documents/' + document.id + '/general'">
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-arrow-right
                  </v-icon>
                </router-link>
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <!-- PC -->
      <v-simple-table v-if="!loading" class="mt-6 documents-table d-none d-lg-block">
        <thead>
          <tr>
            <th colspan="2" width="10%">
              <div class="row">
                <h4 class="year_h4">{{document.attributes['year'] }}</h4>
                <h4 class="product_h4 ml-4">{{document.attributes['product'] }}</h4>
                <div class="symbol_div ml-4">{{document.attributes['symbol'] }}</div>
              </div>
            </th>
            <th>{{ $t("description_uppercase") }}</th>
            <th>{{ $t("date_added_uppercase") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="pointer-mouse" @click="openAttachment(attachment)" v-for="(attachment) in attachments" :key="attachment.id">
            <td width="5%">
              <div :class="attachment.counter % 3 === 0  ? 'yellow-color-square' : attachment.counter % 2 === 0  ? 'orange-color-square' : 'purple-color-square'" class="extension-square row">
                <span>{{ attachment.attributes.extension }}</span>
              </div>
            </td>
            <td width="30%">
              <div class="table-filename">
                {{ attachment.attributes.filename }}
              </div>
            </td>
            <td width="40%">
              <div class="table-description">
                {{ attachment.attributes.description }}
              </div>
            </td>
            <td width="10%">
              <div class="table-date">
                {{ $date(attachment.attributes['created_at']).format('DD.MM.YYYY') }}
              </div>
            </td>
            <td width="10%" class="see-more">
              <a class="see-more" v-bind:href="attachment.attributes.url" target="_blank">
                {{ $t('download') }}
                <v-icon
                  small
                  class="ml-2 arrow-icon"
                >
                  fas fa-arrow-right
                </v-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'Attachments',
  data () {
    return {
      attachments: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters(['document'])
  },
  created () {
    this.$store.dispatch('getDocument', this.$route.params.documentId)
    this.$http.get(`documents/${this.$route.params.documentId}/attachments`)
      .then(response => {
        let counter = 0
        const data = []
        response.data.data.map(attachment => {
          if (counter === 3) {
            counter = 1
          } else {
            counter += 1
          }
          Vue.set(attachment, 'counter', counter)
          data.push(attachment)
        })
        this.attachments = data
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    openAttachment (attachment) {
      window.open(attachment.attributes.url, '_blank')
    }
  }
}
</script>

<style lang="scss">
  .documents-table {
    background-color: transparent !important;
  }

  .table-filename {
    font-size: 18px;
    font-weight: bold;
  }

  .table-description{
    font-size: 14px;
    font-weight: 400;
  }

  .table-date {
    color: #707070;
    font-size: 14px;
  }

  .extension-square {
    color: white !important;
    height: 48px;
    width: 48px;
    background-color: #D20F6E;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;

    span {
      margin-top: 14px;
      margin-left: 14px;
      font-size: 12px;
    }
  }
</style>
